@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.initiative-score-sidePanel {
    max-width: 352px;
    min-width: 270px;
    border-radius: 16px;
    border: 1px solid $surfaces-surface2;
    background: $surfaces-backgroundcolor;
    height: calc(100vh - 16px);
    margin: 8px 8px 8px 0;
    animation: slideIn 0.5s ease-out;
    overflow: hidden;

    .header {
        .header-main {
            padding: 12px 12px 4px 12px;
            .header-main-icon {
                color: $icon-iconcolor;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            .header-main-title {
                color: $text-textcolor;
            }
        }

        .header-sub {
            margin: 4px 4px 0px 4px;
            padding: 12px;
            gap: 8px;
            border-radius: 16px;
            border: 1px solid $surfaces-surface2;
            background: #fff;

            .score-details {
                gap: 12px;
            }

            .score-details-text {
                color: #FF7986;
            }

            .score-description-text {
                color: $text-secondarytextcolor;
            }

            .progress-bar-container {
                width: 100%;
                height: 6px;
                background-color: $surfaces-surface1;
                border-radius: 100px;
                overflow: hidden;
            }
            
            .progress-bar-fill {
                height: 100%;
                background-color: #FF7986;
                border-radius: 100px;
                transition: width 0.3s ease-in-out;
            }
        }
    }

    .content-container {
        height: calc(100vh - 196px);
    }

    .content {
        padding: 8px 4px 32px 4px;
        gap: 4px;
        overflow-y: auto;

        .insight-container {
            padding: 4px;
            border-radius: 16px;
            border: 1px solid $surfaces-surface1;
            background: $surfaces-backgroundcolor;

            .insight-item {
                padding: 8px;
                border-radius: 12px;
                border: 1px solid $surfaces-surface1;
                background: #fff;

                .insight {
                    gap: 12px;
                    padding: 4px;
                }
            }
        }

        .main-content-title {
            color: $text-textcolor;
        }

        .main-content-description {
            color: $text-secondarytextcolor;
        }

        .action-buttons {
            .resolve {
                flex: 1;
            }
        }
    }

    .footer {
        padding: 12px 0px;
        gap: 6px;

        .footer-text {
            color: $text-tertiarytextcolor;
        }
    }

    .resolve-all-button {
        @media (max-width: 1540px) {
            width: 100%;
        }
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}