@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.assistant-panel {
    .message-box-dialog {
        width: 70%;
    }

    .header {
        padding: 0px 0px 8px 8px;
        gap: 8px;

        .header-title {
            color: $text-textcolor;
        }

        .header-icon {
            color: $icon-iconcolor;
        }
    }

    .chat-assistant {
        overflow-y: hidden;
        height: calc(100vh - 82px);

        .chat-assistant-body {
            overflow-y: auto;
            flex: 1;
            padding: 16px 0px 32px 0px;
            gap: 15px;
            flex: 1 0 0;

            :first-child.message-box {
                margin-top: auto;
            }
        
            .message-box {
                gap: 12px;
            
                .message-box-icon {
                    border-radius: 50%;
                    background: $brand-primarylightcolor;
                    width: 32px;
                    height: 32px;
                    flex-shrink: 0; 
                    svg {
                       color: $white;
                    }
                }
            }
        }

        .chat-assistant-footer {
            gap: 8px;
            border-radius: 16px;
            border: 1px solid $surfaces-surface-3;

            .header {
                padding: 12px 8px 4px 12px;
                gap: 8px;

                .add-context {
                    border-radius: 8px;
                    border: 1.222px solid $surfaces-surface1;
                    background: $surfaces-backgroundcolor;
                    padding: 2px 4px 2px 8px;
                    width: fit-content;
                }

                .header-title {
                    color: $text-textcolor;
                }

                .header-icon {
                    color: $icon-iconcolor;

                    svg {   
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .input-box-container {
                border-radius: 16px;
                border: 1px solid $surfaces-surface2;
                background: $surfaces-surface1;
                padding: 8px 4px;
                gap: 8px;

                .input-box {
                    padding: 0px 4px;
                    gap: 4px;
                }

                .description {
                    color: $text-secondarytextcolor;
                    text-align: center;
                    font-family: "DM Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                }
            }
        }
    }

    .message-content {
        height: 100%;
        width: 100%;
    }
}