@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.overview-common-fields {
    padding: 12px;
    border-radius: 16px;
    border: 1px solid $gray-gray60;
    background: $white;
    box-shadow: $shadow-small-soft;

    .body-item {
        padding: 0px 0px 16px 12px;
        border-bottom: 1px solid $gray-gray50;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0px;
        }

        .body-item-label {
            color: $gray-gray90;
        }

        .body-common-container {
            padding: 4px 12px;
        }

        .text-item {
            color: $gray-gray100;
        }
    }
}