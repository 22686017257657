@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.iconButton {
    padding: 6px;
    gap: 8px;
    border-radius: 10px;
    width: 32px;
    height: 32px;

    svg {
        width: 20px;
        height: 20px;
    }

    &:hover, &:focus {
        background-color: $surfaces-surface1;
    }

    &.active, &:active {
        background-color: $surfaces-backgroundcolor;
        color: $brand-primarycolor;

        svg {
            color: $brand-primarycolor;
        }
    }

    svg {
        color: $icon-iconcolor;
        width: 20px;
        height: 20px;
    }

    &.with-background {
        background-color: $surfaces-surface1;
        color: $icon-iconcolor;

        &:hover, &:focus {
            background-color: $surfaces-surface2;
        }

        &.active, &:active {
            background-color: $surfaces-backgroundcolor;
            color: $brand-primarycolor;

            svg {
                color: $brand-primarycolor;
            }
        }
    }

    &.primary {
        background-color: $brand-primarycolor;
        color: #fff;

        &:hover, &:focus {
            background-color: $brand-primarylightcolor;
        }

        &.active, &:active {
            background-color: $brand-primarydarkcolor;
        }
    }

    &.white {
        border-radius: 10px;
        border: 1px solid $surfaces-surface1;
        background: $white;
        box-shadow: 0px 3px 2px -2px rgba(109, 109, 141, 0.06);

        &:hover, &:focus {
            border-radius: 10px;
            border: 1px solid $surfaces-surface1;
            background: rgba(255, 255, 255, 0.60);
            box-shadow: 0px 3px 2px -2px rgba(109, 109, 141, 0.06);
        }

        &.active, &:active {
            background: $surfaces-backgroundcolor;
            color: $brand-primarycolor;

            svg {
                color: $brand-primarycolor;
            }
        }
    }

    &.small {
        padding: 4px;
        width: 28px;
        height: 28px;
    }

    &.disabled {
        opacity: 0.5;
    }
}
