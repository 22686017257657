@import 'designSystem.scss';
@import 'variables.scss';

.tooltip {
    background-color: white;
    border: 1px solid $surface2;
    border-radius: 4px;
    padding: 8px 12px;
}
  
.tooltip-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 0;
}

.tooltip-label {
    opacity: 1;
    font-weight: 500;
}

.tooltip-value {
    font-weight: 600;
}