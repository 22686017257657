@import 'constants.scss';



.container{
    margin: 0 10px;
    width: 51px;

    .saving {
        color: #5C5E63;
        font-size: 12px;
    }
    
    .saved {
        color: $primaryColor;
        font-size: 12px;

        .savedIcon {
            height: 12px;
            width: 15px;
        }
    }

}