@import 'constants.scss';
@import 'commonStyles.scss';

.mainContainer {
    overflow: hidden;
    flex-shrink: 0;
    height: calc(100vh - $headerHeight);
    flex-direction: column;
    padding: 24px;
    position: sticky;
    top: $headerHeight;
    left: 0;
    justify-content: space-between;
    border-right: 1px solid $surface1;

    .logoContainer {
        // padding-left: 16px;
        // padding-right: 16px;
        margin-bottom: 44px;
        height: 32px;
        justify-content: space-between;

        .logoLink {
            margin-right: 5px;

            img {
                width: 120px;
            }
        }

        .collapseButton {
            padding: 0;
            color: #828282;
            height: 26px;
            width: 26px;
            border-radius: 6px;

            &:hover {
                background: #ebebeb;
            }

            svg {
                width: 20px;
            }
        }
    }

    .backButtonContainer {
        margin-left: 16px;
        margin-bottom: 50px;
        cursor: pointer;
        color: #828282;
    }

    .loaderBox {
        height: 100%;
    }

    .bottomMenuItemsContainer {
        margin-top: 20px;
    }

    .expandButtonContainer {
        margin-top: 40px;
    }

    .expandedButton {
        justify-content: flex-end;
    }

    &.sidebarCollapsed {
        overflow-x: hidden;

        .logoContainer {
            justify-content: center;
        }

        .bottomMenuItemsContainer {
            align-items: center;
        }
    }

    .nav-container {
        height: calc(100vh - 188px);
        overflow: auto;
    }
}