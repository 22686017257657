@import 'constants.scss';
@import 'commonStyles.scss';

.emptyContainer{
    margin: auto;
    padding-bottom: 9%;

    .emptyIcon{
        color: #EBEBEB;
    }
    .titleLarge{
        font-size: 24px;
        font-weight: 600;
        line-height: 1.5em;
        color: #C4C4C4;
        margin-bottom: 24px;
        margin-top: 8px;
    }
    .titleSmall{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5em;
        color: $textColor; 
        margin-bottom: 16px 
    }
    .emptyButton{
        svg{
            font-size: 16px;
            margin-left: 8px;
        }
    }
}