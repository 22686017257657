@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.input-container {
    color: $text-textcolor;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.09px;
    height: 40px;

    .input-container__left {
        text-align: center;
        border-radius: 12px 0 0px 12px;
        padding: 0px 12px;
        background: $surfaces-surface2;
        color: #ACACAC;
        font-size: 15px;
        line-height: 20px;
    }

    .input-container__right {
        border-radius: 0px 12px 12px 0px;
        background: $surfaces-surface1;
        padding: 0px 12px;

        &.full {
            border-radius: 12px;
        }
    }

    .input-container__input {
        width: 80%;

        & :global(.MuiInputBase-root) {
            width: 100%;
            border: none;
            height: 100%;
            box-sizing: border-box;
            padding: 0;
        }

        & :global(.MuiOutlinedInput-notchedOutline) {
            border: none;
        }

        & :global(.MuiOutlinedInput-input) {
            padding: 0;
        }
    }
}