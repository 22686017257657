@import 'constants.scss';
@import 'commonStyles.scss';

.sourcePopupButton {
    width: 250px;
    padding: 6px 12px 6px 17px;
    border-radius: 16px;
    border: 1px solid $surface1;
}

.sourcePopup-primaryTitle {
    color: $brandPrimaryColor;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.sourcePopup-actionButton {
    padding: 10px;
    border-radius: 10px;
    background: $surfacesBackgroundColor;
    
    svg {
        color: #303030;
    }
}

.sourcePopupLinked {
    width: 400px;
    height: 60vh;
    padding: 12px;
    gap: 16px;

    .sourcePopupLinked-heading {
        border-bottom: 1px solid $surface1;
    }

    .sourcesCountTitle {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
    }

    .sources-content {
        height: calc(100% - 68px);
    }

    .source-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        overflow-wrap: break-word;
        overflow: hidden;
    }

    .sourceContainer {
        padding-left: 10px;
        .sourceLink-box {
            width: 100%;
            border-radius: 16px;
            border: 1px solid $surface2;
            background: #FFF;

            .source-doc {
                width: calc(100% - 40px);
            }

            &.error {
                border: 1px solid $accentError; 
            }

            &:hover {
                border: 1px solid $brandPrimaryColor;
                text-decoration-line: underline;
                color: $brandPrimaryColor;
                transition: all 0.5s ease-in;

                .source-title {
                    color: $brandPrimaryColor;
                }
            }

        }
    }

    .sourceType-iconBox {
        width: 32px;
        height: 32px;
        padding: 7px 6.714px 6.714px 7px;
        border-radius: 200px;
        background: $surface1;
    }

    .sourceErrorSection {
        font-size: 15px;
        line-height: 20px;

        .errorText {
            color: $accentError;
        }

        .primaryText {
            color: $primaryTextColor;
        }

        .linkText {
            color: $accentLink;
        }
    }

    .sourcePopupLinked-footer {
        .sourcePopupLinked-footerText {
            color: #B8B8B8;
            text-align: center;
        }
    }
}

.sourcePopup {
    border-radius: 16px;
    border: 1px solid $surface2;
    background: #FFF;
    box-shadow: $widget;
}

.sourcePopup-addTitle {
    font-size: 16px;
    font-weight: 500;
} 

.linkNodeInputBox {
    height: 48px;

    .linkNodeInputBox__left {
        text-align: center;
        border-radius: 12px 0 0px 12px;
        padding: 6px 12px 6px 8px;
        background: $surface2;
        color: #ACACAC;
        font-size: 15px;
        line-height: 20px;
    }

    .linkNodeInputBox__right {
        border-radius: 0px 12px 12px 0px;
        background: $surface1;
        padding: 6px 8px;
    }


    .linkNodeInput {
        width: 80%;

        & :global(.MuiInputBase-root) {
            width: 100%;
            border: none;
            height: 100%;
            box-sizing: border-box;
            padding: 0;
        }

        & :global(.MuiOutlinedInput-notchedOutline) {
            border: none;
        }

        & :global(.MuiOutlinedInput-input) {
            padding: 0;
        }
    }
}

.sourcePopupAdd-main__title {
    font-size: 15px;
    font-weight: 500;
}

.sourcePopupAdd-footer {
    .sourcePopupAdd-footerText {
        color: #404040;
        font-size: 14px;
        &.error {
            color: $accentError;
        }
    }
}

.sourceName {
    color: $secondaryTextcolor;
    font-size: 16px;
    font-weight: 500;
}