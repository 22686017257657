@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.commonButton {
    gap: 4px;
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.09px;

    &:global(.Mui-disabled) {
        opacity: 0.5;
    }

    & :global(.MuiButton-startIcon) {
        margin-right: 0px;
        font-size: 16px;
    }

    &.primary {
        background: $brand-green300;
        color: $white;

        &:hover {
            background: $brand-primarylightcolor
        }
    }

    &.action {
        background: $brand-primarydarkcolor;
        color: $white;

        &:hover {
            background: $brand-primarylightcolor
        }
    }

    &.secondary {
        background-color: $surfaces-surface1;
        color: $text-textcolor;
        
        &:hover {
            background: $surfaces-surface2;
        }
    }

    &.shadow {
        border: 1px solid $surfaces-surface2;
        background: $white;
        box-shadow: $shadow-simple-button;
        color: $text-textcolor;
        
        &:hover {
            background-color: $surfaces-backgroundcolor;
        }
    }

    &.text {
        background: $white;
        color: $text-secondarytextcolor;
        border: 1px solid transparent;

        &:hover {
            border: 1px solid $surfaces-surface1;
            background: $surfaces-backgroundcolor;
            box-shadow: $shadow-simple-button;
        }
    }

    &.black {
        background: $gray-gray100;
        color: $white;

        &:hover {
            color: $brand-green80;
        }
    }

    &.big {
        border-radius: 14px;
        padding: 14px 24px;
    }

    &.large {
        border-radius: 12px;
        padding: 10px 24px;
    }

    &.medium {
        border-radius: 10px;
        padding: 8px 20px;
    }

    &.small {
        border-radius: 10px;
        padding: 6px 12px;
    }
}