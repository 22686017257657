@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.generatePRDPopup {
    width: 100%;
    height: 100%;
    max-width: 816px;
    padding: 6px;
    border-radius: 20px;
    border: 1px solid $surfaces-surface2;
    background: rgba(255, 255, 255, 0.96);
    box-shadow: 0px 8px 28px -6px rgba(28, 40, 64, 0.12), 0px 20px 92px -4px rgba(80, 86, 97, 0.08);
    backdrop-filter: blur(3.5px);
    height: fit-content;
}

.generatePRDContent {
    width: 100%;
    height: 100%;
    padding: 48px 8px 24px 8px;
    gap: 48px;

    .generatePRDContent {
        border: 1px solid $surfaces-surface2;
        background: $white;
        box-shadow: $shadow-big;
        border-radius: 16px;
    }

    .header {
        max-width: 540px;
        gap: 16px;

        .headerTitle {
            color: $text-textcolor;
            text-align: center;
        }

        .headerSubtitle {
            max-width: 460px;
            color: $text-secondarytextcolor;
            font-family: 'Inter';
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.096px;
            text-align: center;
        }
    }

    .body {
        width: 88%;
        max-width: 720px;
        padding: 8px 4px 4px 4px;
        gap: 8px;
        border-radius: 20px;
        border: 1px solid $surfaces-surface2;
        background: $surfaces-surface1;

        .selectTemplateContainer {
            padding: 4px 8px;
        }

        .label {
            color: $text-secondarytextcolor;
        }

        .initiativeInputContainer {
            padding: 16px 10px 10px 16px;
            border-radius: 16px;
            border: 1px solid $surfaces-surface2;
            background: $white;
            font-family: 'Inter';
            gap: 8px;

            &:hover {
                border: 1px solid $brand-primarylightcolor;
            }

            &:active, &:focus, &:focus-visible, &:focus-within {
                border: 1px solid $brand-primarydarkcolor;
            }

            .initiativeInput {
                & :global(.MuiInputBase-root) {
                    width: 100%;
                    border: none;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 0;
                }
        
                & :global(.MuiOutlinedInput-notchedOutline) {
                    border: none;
                }
        
                & :global(.MuiOutlinedInput-input) {
                    padding: 0;
                }
            }

            .inputButtonContainer {
                gap: 8px;
            }
        }
    }

    .footer {
        .footerText {
            color: $text-tertiarytextcolor;
        }

        .footerLink {
            color: $brand-primarylightcolor;
        }
    }
}

