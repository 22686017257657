@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.accordion-component-container {
    :global(.MuiPaper-root.accordion-component) {
        padding: 16px;
        background-color: $white;
        border-radius: 16px;
        border: 1px solid $surface2;
        width: 100%;
        box-shadow: none;
    }

    :global(.MuiAccordionSummary-root.Mui-focusVisible) {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }

    &.small {
        :global(.MuiPaper-root.accordion-component) {
            border: none;
            padding: 0;
        }

        .accordion-summary-line {
            width: 100%;
            height: 1px;
            background: $surfaces-surface2;
        }
    }

    .accordion-title {
        color: $text-color;
        font-family: "Inter";
        font-size: 18px;
        font-weight: 700;
        line-height: 20px; /* 111.111% */
        letter-spacing: -0.18px;
        margin: 0;

        &.small {
            font-size: 15px;
            font-weight: 400;
            line-height: 24px; /* 160% */
            letter-spacing: -0.075px;
        }
    }

    .accordion-summary {
        padding: 0;
        margin: 0;
        min-height: 0;

        :global(.MuiAccordionSummary-content) {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        :global(.MuiAccordionSummary-expandIconWrapper) {
            padding: 4px ;

            svg {
                width: 20px;
                height: 20px;
                color: #2F3237;
            }
        }
    }

    .accordion-details {
        padding: 0;
        margin: 20px 0;
    }

    .accordion-summary--editable {
        gap: 32px;
        width: 100%;

        .handle-icon {
            cursor: grab;
        }

        .title-editor-input-container {
            width: 100%;
            min-width: 360px;
            padding: 6px 12px;
            gap: 4px;
            border-radius: 10px;
            opacity: 0.8;
            background: $surfaces-surface2;

            input {
                border: none;
                outline: none; 
                background: transparent;
                width: 100%;
                color: $text-secondarytextcolor;
                font-family: 'Inter';
                font-size: 18px;
                font-weight: 400;
                line-height: 24px; /* 133.333% */
                letter-spacing: -0.54px;
            }
        }
    }

}