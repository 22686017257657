@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.content-section-layout {
    border-radius: 16px;
    font-family: $font-family;
    background: $surfaces-backgroundcolor;
    border: 1px solid $surfaces-surface2;

    .content-section-header {
        padding: 12px;
        border-bottom: 1px solid $surface1;
        background: $white;
        border-radius: 16px 16px 0px 0px;
        z-index: 2;

        .title-container {
            svg {
                color: $gray-gray100;
            }
        }

        .title {
            color: $text-color;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px; /* 125% */
            letter-spacing: -0.096px;
        }
    }

    .content-section-body {
        padding: 12px;
        background: $gray-gray40;
        border-radius: 0px 0px 16px 16px;
    }

    .content-section-main {
        border-radius: 16px;
        border: 1px solid $surface2;
        background: $white;
        padding: 16px;
    }
}
