@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.mockups-container {
    gap: 8px;
    background: $surfaces-backgroundcolor;
    width: 100%;

    .mockups-container-header {
        gap: 10px;

        .section-title {
            color: $text-color;
        }
    }

    .mockup-item {
        height: 232px;
        width: 288px;
        border-radius: 12px;
        border: 1px solid $surfaces-surface2;
        background: $white;
        padding: 4px 4px 8px 4px;
        gap: 4px;
        align-items: flex-start;

        .mockup-item-title-container {
            width: 100%;
            padding: 0 4px;
        }

        .mockup-title {
            color: $text-secondarytextcolor;
        }

        .mockup-item-content {
            position: relative;

            .mockup-item-content-action-button {
                position: absolute;
                top: 4px;
                right: 4px;
            }
        }
    }
}