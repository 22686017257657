@import 'commonStyles.scss';
@import 'constants.scss';

@keyframes tooltipPop {
    0% {
        opacity: 0;
        transform: scale(0.4) translateY(10px);
    }
    50% {
        transform: scale(1.2) translateY(-5px);
    }
    70% {
        transform: scale(0.95) translateY(2px);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

.custom-pie-chart {
    position: relative;
    overflow: visible;
    margin: 1rem;
    font-family: "DM Sans";

    :global(.recharts-tooltip-wrapper) {
        animation: tooltipPop 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        pointer-events: none;
    }

    .custom-tooltip {
        background: rgba(255, 255, 255, 0.98);
        border-radius: 8px;
        padding: 12px 16px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        border: 1px solid rgba(0, 0, 0, 0.06);
        backdrop-filter: blur(8px);
        
        strong {
            display: block;
            margin-bottom: 4px;
            color: #2c3e50;
            font-size: 14px;
        }
        
        div {
            color: #5c6c7c;
            font-size: 13px;
            font-weight: 500;
        }

        @keyframes tooltipFade {
            from {
                opacity: 0;
                transform: scale(0.95) translateY(5px);
            }
            to {
                opacity: 1;
                transform: scale(1) translateY(0);
            }
        }
    }

    .inner-text {
        font-size: 14px;
        font-weight: 500;
        fill: #4a5568;
        user-select: none;
    }

    .legend-items {
        padding: 12px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        backdrop-filter: blur(8px);
    }

    .legend-title {
        font-size: 13px;
        font-weight: 600;
        color: #2d3748;
        margin-bottom: 8px;
    }

    .legend-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 8px;
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.2);
        }
    }

    .legend-percentage {
        margin-left: auto;
        font-size: 13px;
        color: #718096;
        font-weight: 500;
    }

    .section {
        background: rgba(255, 255, 255, 0.8);
        border-radius: 12px;
        padding: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(0, 0, 0, 0.06);
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
        }
    }

    .sectionHeader {
        margin-bottom: 16px;
    }

    .title {
        font-size: 16px;
        font-weight: 600;
        color: #2d3748;
        margin-bottom: 4px;
    }

    .description {
        font-size: 14px;
        color: #718096;
    }
}