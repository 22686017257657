@import 'constants.scss';

.mainContainer {
    height: 100%;

    .datePickerBox {
        align-self: center;
    }

    .tableContainer {
        align-items: flex-start;
        width: 100%;
        height: 100%;
    }
}
