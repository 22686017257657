@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';


.okr-impact-container {
    border-radius: 16px;
    border: 1px solid $surfaces-surface2;
    background: $white;
    padding: 16px;
    gap: 16px;

    .okr-impact-container-header {
       height: 28px;
    }

    .title {
        color: $text-textcolor;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.096px;
    }

    :global {
        .MuiPaper-root.MuiTableContainer-root {
            border-radius: 12px;
            border: 1px solid $surfaces-surface1;
            box-shadow: none;
        } 

        .MuiTableCell-root.MuiTableCell-head {
            background: $surfaces-backgroundcolor;
            padding: 8px 16px;
            color: $text-secondarytextcolor;
            font-family: "Inter";
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.084px;
            border-bottom: 0px solid $surfaces-surface1;
        }

        .MuiTableCell-root {
            border-bottom: 1px solid $surfaces-surface1;
            color: var(--Text-TextColor, #2F3237);
            font-family: "Inter";
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.084px;
        }
    }

    :global {
        .text-field{
            .MuiInputBase-root, .MuiOutlinedInput-root {
                width: 142px;
                padding: 6px 12px;  
                gap: 8px;
                border-radius: 10px;
                background: $surfaces-surface1;
                color: $text-tertiarytextcolor;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.084px;

                input {
                    padding: 0px;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        .checkbox {
            &.MuiCheckbox-root {
                padding: 4px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
            &.MuiCheckbox-colorPrimary {
                color: $surfaces-surface2;
            }
            &.MuiCheckbox-colorPrimary.Mui-checked {
                color: $brand-primarycolor;
            }
        }
    }
}