@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.searchableSelectContainer {
    :global {
        .searchableSelect {
            .MuiSelect-select {
                padding: 0;
                border: none;
                background-color: transparent;
            }
        }
        
        .MuiSelect-root {
            border: none;
            fieldset {
                border: none;
            }
        }

        .MuiOutlinedInput-root {
            border: none;
            fieldset {
                border: none;
            }
        }

        .MuiInputBase-root {
            border: none;
            &:before, &:after {
                display: none;
            }
        }

        .MuiSelect-icon {
            display: none;
        }
    }
}

.searchableSelect-menuPaper {
    width: 280px;
    height: 212px;
    border-radius: 20px;
    border: 1px solid $surfaces-surface2;
    background: rgba(255, 255, 255, 0.96);
    box-shadow: 0px 4px 10px -4px rgba(140, 130, 154, 0.15), 1px 5px 5px -5px rgba(140, 130, 154, 0.10);
    backdrop-filter: blur(3.5px);
    margin-top: 8px;

    :global {
        .MuiList-root {
            padding: 0px;
            margin: 0;
        }
    }
}

.searchableSelect-menu {
    overflow: hidden;
    color: $text-secondarytextcolor;

    .searchableSelect-title {
        color: $text-textcolor;
    }
}

.searchableSelect-searchInput {
    border: none;
    padding: 12px;
    box-sizing: border-box;
    border-bottom: 1px solid $surfaces-surface1;

    :global {
        .MuiInputBase-root {
            border: none;
            padding: 0;
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
            padding: 0;
        }

        .MuiInputAdornment-root {
            display: flex;

            padding: 2px;
            justify-content: center;
            align-items: center;

            svg {
                width: 16px;
                height: 16px;
                fill: $text-secondarytextcolor;
            }
        }

        .MuiInputBase-input {
            padding: 0;
            color: $text-secondarytextcolor;
            /* Caption/Caption 2 Regular */
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.084px;
        }
    }
}

.searchableSelect-menuItems {
    width: 100%;
    height: calc(212px - 45px);
    overflow-y: auto;
    padding: 4px;
    gap: 4px;

    .searchableSelect-menuItem {
        padding: 6px;
        gap: 2px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;

        .searchableSelect-menuItem-label {
            color:$text-secondarytextcolor;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 240px;
        }

        .searchableSelect-menuItem-checkable {
            color: $brand-primarylightcolor;
         
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}