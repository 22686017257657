@import 'constants.scss';
@import 'commonStyles.scss';

.company-container {
    margin: 40px;
    border: 1px solid $surface2;
    border-radius: 10px;
    padding: 40px;
    background-color: #fff;
    gap: 16px;

    .company-info {
        background-color: $surface1;
        border-radius: 10px;
        padding: 20px;
        height: auto;
    }

    .company-name {
        font-size: 24px;
        font-weight: 600;
    }

    .company-input {
        border: 1px solid $surface2;
        border-radius: 10px;
        padding: 10px;
        background-color: #fff;

        &.name {
            font-size: 14px;
        }
    }

    .description {
        height: 100%;
        background-color: #fff;

        :global {
            .tox.tox-tinymce {
                background-color: #fff !important;
            }
        }
    }   

    .button-container {
        button {
            font-size: 14px;
            padding: 6px 16px;
        }
    }
}
