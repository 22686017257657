@import 'constants.scss';

.gap-1 {
    gap: 4px;
}

.gap-2 {
    gap: 8px;
}

.gap-3 {
    gap: 12px;
}

.gap-4 {
    gap: 16px;
}

.gap-5 {
    gap: 20px;
}

.gap-6 {
    gap: 24px;
}

.gap-7 {
    gap: 28px;
}

.gap-8 {
    gap: 32px;
}

.gap-9 {
    gap: 36px;
}

.gap-10 {
    gap: 40px;
}

.gap-16 {
    gap: 64px;
}

.row-gap-2 {
    row-gap: 8px;
}

.p-1 {
    padding: 4px;
}

.p-2 {
    padding: 8px;
}

.p-3 {
    padding: 12px;
}

.p-4 {
    padding: 16px;
}

.pb-3 {
    padding-bottom: 12px;
}

.pb-4 {
    padding-bottom: 16px;
}

.pb-6 {
    padding-bottom: 24px;
}

.pt-3 {
    padding-top: 12px;
}

.pt-4 {
    padding-top: 16px;
}

.pt-5 {
    padding-top: 20px;
}

.pt-6 {
    padding-top: 24px;
}

.pr-2 {
    padding-right: 8px;
}

.pr-4 {
    padding-right: 16px;
}

.pl-4 {
    padding-left: 16px;
}

.pl-5 {
    padding-left: 20px;
}

.pl-10 {
    padding-left: 40px;
}

.py-6 {
    padding: 24px 0;
}

.px-5 {
    padding-left: 20px;
    padding-right: 20px;
}

.mt-2 {
    margin-top: 8px;
}

.mt-3 {
    margin-top: 12px;
}

.mt-4 {
    margin-top: 16px;
}

.mb-2 {
    margin-bottom: 8px;
}

.mb-4 {
    margin-bottom: 16px;
}

.mb-5 {
    margin-bottom: 20px;
}

.ml-3 {
    margin-left: 12px;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.h-fit {
    height: fit-content;
}

.h-screen {
    height: 100vh;
}

.ellipsisText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.text-end {
    text-align: end;
}

.break-word {
    word-break: break-word;
}

.accentSuccess {
    color: $accentSuccess;
    background-color: rgba($color: $accentSuccess, $alpha: 0.2);
}

.accentCaution {
    color: $accentCaution;
    background-color: rgba($color: $accentCaution, $alpha: 0.2);
}

.accentError {
    color: $accentError;
    background-color: rgba($color: $accentError, $alpha: 0.1);
}

.accentDisabled {
    color: $accentDisabled;
    background-color: rgba($color: $accentDisabled, $alpha: 0.1);
}

.accentCritical {
    color: $accentCritical;
    background-color: rgba($color: $accentCritical, $alpha: 0.1);
}

.accentSuccess-bgColor {
    background-color: $accentSuccess;
}

.accentCaution-bgColor {
    background-color: $accentCaution;
}

.accentError-bgColor {
    background-color: $accentError;
}

.font-500 {
    font-weight: 500;
}

.input-error {
    color: #C62828;
    font-size: 11px;
    padding-top: 4px;
}

.color-white {
    color: #fff;
}

.bg-primaryColor {
    background-color: #009965;

    &:hover {
        background-color: #025B62;
    }
}

.bg-surface {
    background-color: $surface1;

    &:hover {
        background-color: $surface2;
    }
}

.color-primaryTextColor {
    color: $primaryTextColor;
}

.cursor-pointer {
    cursor: pointer;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.beta-tag {
    background-color: #ce4500;
    color: white;   
    font-weight: bold;      
    padding: 3px 6px;       
    font-size: 8px;      
    border-radius: 4px;     
    margin-left: 4px;      
    vertical-align: middle; 
}

.flex-1 {
    flex: 1;
}