@import 'commonStyles.scss';
@import 'variables.scss';

.statistics {
    flex-wrap: wrap;

    .statistics-card {
        height: 428px;
        min-width: 350px;
        padding: 16px;
        flex: 1 0 0;
        border-radius: 16px;
        border: $border-surface2;
        background: $background-white;
        box-shadow: $shadow-long;

        .heading {

            .total-value-container {
                padding: 4px 8px;
                border-radius: 10px;
                border: 1px solid $gray-accents-green-100;
                background: $gray-accents-green-20;
                color: $gray-accents-green-text;
                height: 28px;
                width: 34px;
            }

            .title{
                color: $text-color;
            }
    
            .description{
                color: $text-secondary;
            }
    
            .description-value {
                color: $text-color;
            }
        }

    }


    .total-plans {
        .metrics-container {
            display: flex;
            padding: 12px 16px;
            gap: 2px;
            align-self: stretch;
            border-radius: 16px;
            border: 1px solid $surface1;
            background: $background-white;
        }

        .stepper {
            :global(.MuiStepIcon-text) {
                display: none;
            }

            :global(.MuiStepIcon-root) {
                width: 14px;
                height: 14px;
                border-radius: 50%;
            }

            :global(.MuiStepContent-root) {
                border-left: 1px solid $surface1;
                padding-right: 0;
            }

            :global(.MuiStepLabel-label) {
                color: $text-color;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .step-content {
                .dot {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    
                    &.active, &.pushedOut {
                        background-color: $gray-accents-light-purple-100;
                    }

                    &.completed, &.replanned {
                        background-color: $gray-accents-green-100;
                    }

                    &.notStarted {
                        background-color: $gray-accents-red-orange-100;
                    }
                }
            }
        }
    }
}