@import 'variables.scss';

// ____________________________New Design System
/* Paragraph/Paragraph Regular */
.regular-paragraph {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

/* Paragraph/Paragraph 2 Bold */
.paragraph-2-bold {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

/* Body/Body Bold */
.body-bold {
    font-family:"Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px; /* 133.333% */
}

/* Caption/Caption 2 Regular */
.caption-2-regular {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.084px;
}

/* Paragraph/Paragraph 15/Paragraph 15 Medium */
.paragraph-15-medium {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
}

/* Paragraph/Paragraph 15/Paragraph 15 Regular */
.paragraph-15-regular {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.09px;
}

/* Paragraph Readable/Paragraph Regular */
.paragraph-readable-regular {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; /* 160% */
    letter-spacing: -0.075px;
}

/* Caption/Caption 2 Medium */
.caption-2-medium {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.09px;
}

/* Heading/H4 Bold */
.h4-bold {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.48px;
}

/* Paragraph/Paragraph 16/Paragraph 16 Bold */
.paragraph-16-bold {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.096px;
}

/* Paragraph Readable/Paragraph 15/Paragraph Regular */
.paragraph-15-readable-regular {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; /* 160% */
    letter-spacing: -0.075px;
}

/* Paragraph/Paragraph 15/Paragraph 15 Bold */
.paragraph-15-bold {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.09px;
}

/* Paragraph Readable/Paragraph 16/Paragraph 16 Regular */
.paragraph-16-readable-regular {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.096px;
}

/* Heading/H3 Semi Bold */
.h3-semi-bold {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 600;
    line-height: 32px; /* 100% */
    letter-spacing: -1.28px;
}

/* Paragraph/Paragraph 16/Paragraph 16 Medium */
.paragraph-16-medium {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px; /* 125% */
    letter-spacing: -0.096px;
}

/* Heading/H5 Bold */
.h5-bold {
    font-family: "Inter";
    font-size: 22px;
    font-weight: 700;
    line-height: 24px; /* 109.091% */
    letter-spacing: -0.44px;
}