@import 'constants.scss';
@import 'commonStyles.scss';

.mainContainer {
    overflow: auto;

    .tabContainer {
        width: 860px;
        padding: 0 38px;

        .tabRow {
            .tabCol {
                width: 50%;
                &.first {
                    margin-right: 24px;
                }
            }

            .labelIcon {
                cursor: pointer;
            }
        }

        .tabColSpacing {
            max-width: 681px;
            grid-column-gap: 24px;
        }

        .column {
            width: 330px;
            margin-right: 24px;
        }
    }

    .tabs {
        padding: 24px 0 32px 40px;
        overflow: auto;

        :global(.MuiTabs-scroller) {
            overflow: auto !important;
        }
    }

    .okrSelectContainer {
        margin-bottom: 32px;
    }

    .selectedOkrsContainer {
        padding: 0 38px;

        .selectedOkrsBox {
            margin-bottom: 48px;
            box-shadow: 0px 0px 10px 5px rgba(53, 53, 53, 0.14);
            border-radius: 6px;
        }
        .selectedOkrHead {
            padding: 12px 10px;
            justify-content: space-between;
        }

        .okrExpandBtn {
            color: $textColor;
            font-size: 12px;
            width: 10px;
            cursor: pointer;
            margin-right: 10px;
        }

        .okrExpandTitle {
            color: $textColor;
            font-size: 16px;
            font-weight: 600;
        }

        .selectedOkrContainer {
            margin: 0 40px 16px 28px;

            .selectedOkrTitleContainer {
                width: 100%;
                background-color: rgba(26, 115, 32, 0.2);
                color: $secondaryColor;
                border-radius: 6px;
                align-items: center;
                justify-content: space-between;
                padding: 16px;

                .closeButtonContainer {
                    color: #828282;
                    cursor: pointer;
                }
            }
            .krTableContainer {
                padding-left: 16px;
            }

            .impactSelect {
                width: 120px;

                :global {
                    .inputComponentMainContainer {
                        .inputRoot {
                            .MuiFilledInput-root {
                                background: #fff;

                                &.Mui-focused {
                                    border-color: $primaryColor;
                                }

                                .MuiAutocomplete-popupIndicator {
                                    opacity: 1;
                                }

                                input {
                                    padding: 5px 7px;
                                }
                            }
                        }
                    }
                }

                &.noImpact {
                    input {
                        color: #c4c4c4;
                    }
                }

                :global {
                    .inputRoot {
                        .Mui-focused {
                            border-color: transparent;
                        }
                    }
                }

                &.yellow {
                    :global {
                        .inputRoot {
                            background: rgba(255, 165, 0, 0.04);
                            border-color: rgba(255, 165, 0, 0.5);
                        }
                    }
                }

                &.blue {
                    :global {
                        .inputRoot {
                            background: rgba(0, 117, 255, 0.04);
                            border-color: rgba(0, 117, 255, 0.5);
                        }
                    }
                }

                &.red {
                    :global {
                        .inputRoot {
                            background: rgba(255, 80, 80, 0.04);
                            border-color: rgba(255, 80, 80, 0.5);
                        }
                    }
                }
            }

            .actualImpactInput {
                :global {
                    .inputRoot {
                        .MuiFilledInput-root {
                            background: #fff;
                            input {
                                padding: 5px 7px;
                            }
                        }
                    }
                }
            }

            .metricName {
                font-weight: 700;
                &.noImpact {
                    color: #c4c4c4;
                    font-weight: 500;
                }
            }

            .iconTableHeader {
                color: #5a5a5a;
                font-size: 14px;
                margin-left: 4px;
            }
        }
    }

    .storiesContainer {
        padding: 0 30px;
        .tableContainer {
            margin-bottom: 38px;
        }

        .iconsContainer {
            color: #828282;
            .icon {
                cursor: pointer;
                margin-right: 21px;
            }
        }
    }

    // Start Info Box
    .infoContainer {
        padding: 32px 38px 0px;

        .infoTopBox {
            align-items: flex-start;
            justify-content: space-between;

            .actionMenu {
                align-self: center;
            }

            .infoTitleBox {
                font-size: 24px;
                max-width: 900px;
                margin-left: -8px;
                font-weight: 700;

                .infoTitleInput {
                    color: #2F3237;
                    font-size: 24px;
                    font-weight: 700;
                    padding: 4px 8px;
                    border-radius: 8px;
                    letter-spacing: -0.02em;

                    &:hover {
                        background-color: #F4F5F6;
                    }

                    ::placeholder {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .selectStatus {
        margin: 0 8px;
    }
    // End Info selectBox
}

.menuItem {
    font-size: 14px;
    padding: 4px 10px;
    margin-bottom: 2px;
}

.moveToLiveDialogContainer {
    max-width: 400px;
}

.initiativeLoadingText {
    margin-top: 8px;
}

.refineTextArea {
    width: 400px;
}

.resourceManagement {
    margin-bottom: 60px;
    width: calc(100vw - 227px);
    padding: 0 38px;
    .row {
        margin-bottom: 24px;
        .label {
            font-size: 12px;
            line-height: 21px;
            color: $primaryColorDark;
        }

        :global {
            .b-grid-header-text {
                padding: 0;
                font-size: 12px;
            }
            .b-sch-header-timeaxis-cell {
                font-size: 12px;
            }
            .b-grid-cell {
                font-size: 14px;
            }
        }
    }

    .viewPresetMainContainer {
        position: relative;
        .viewPresetContainer {
            position: absolute;
            bottom: -45px;
            right: 0;
        }
    }
}

.comment-field-container {
    margin-bottom: 24px;
}

.comment-button-container {
    padding-top: 16px;
}

.mb24 {
    margin-bottom: 24px;
}

// Grading
.gradingContainer {
    .mediumYellow {
        color: $mediumYellow;
    }

    .mediumOrange {
        color: $mediumOrange;
    }

    .mediumRed {
        color: $mediumRed;
    }

    .darkRed {
        color: $darkRed;
    }

    .darkestRed {
        color: $darkestRed;
    }

    .bgMediumGreen {
        background-color: $mediumGreen;
    }

    .bgMediumYellow {
        background-color: $mediumYellow;
    }

    .bgRed {
        background-color: $red;
        color: $white;
    }

    .bgMediumRed {
        background-color: $mediumRed;
    }

    .bgDarkRed {
        background-color: $darkRed;
    }

    .bgLightRed {
        background-color: $lightRed;
    }

    .scoreBox {
        padding: 4px 16px 4px 16px;
        border-radius: 6px;
        height: fit-content;
    }

    .initiativeState {
        border: 2px solid #c4c4c4;
        border-radius: 6px;
        padding: 20px 40px 20px 20px;
        color: $secondaryColor;
        font-weight: 600;

        .stateLabel {
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 4px;
        }

        .stateValue {
            font-size: 24px;
            line-height: 36px;
        }
    }

    .storiesProgressContainer {
        height: 100%;
        padding: 24px 2px 16px 2px;
        border: 2px solid #c4c4c4;
        border-radius: 6px;
        margin-bottom: 24px;

        .headerContainer {
            padding: 0px 20px;
            margin-bottom: 6px;
            justify-content: space-between;
            align-items: center;
        }

        .headerTitle {
            color: $secondaryColor;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
        }
    }

    .grading {
        padding: 16px 0px 16px 0px;
        gap: 24px;
        margin-bottom: 24px;
        color: $secondaryColor;

        .gradingGroup {
            gap: 24px;
        }

        .titleContainer {
            align-items: center;
            justify-content: center;
            gap: 12px;
            font-weight: 600;

            .gradingTitle {
                font-size: 18px;
                line-height: 21px;
            }

            .gradingValue {
                font-size: 24px;
                line-height: 48px;
            }
        }

        .groupContainer {
            justify-content: space-between;
            align-items: center;
            padding: 24px 20px 8px 20px;

            &.closed {
                border-bottom: 1px solid #c4c4c4;
            }

            .groupTitle {
                line-height: 21px;
                font-weight: 700;
                font-size: 18px;
            }
        }

        .gradingBox {
            padding: 16px 40px 16px 20px;
            border: 2px solid #c4c4c4;
            background: $formElementBackground;
            border-radius: 6px;
            gap: 24px;

            .spaceBetween {
                justify-content: space-between;
            }

            .gradingContent {
                width: 100%;
                gap: 12px;
            }

            .gradTitleContainer {
                gap: 8px;
            }

            .feedbackTitle {
                font-size: 16px;
                line-height: 21px;
                font-weight: 600;
            }

            .feedbackDescription {
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
            }

            .actionButtonContainer {
                align-items: center;
            }

            .actionButton {
                width: 200px;
                height: min-content;
            }

            .insightTitle {
                font-size: 18px;
                line-height: 23px;
                font-weight: 700;
                margin-bottom: 12px;
                color: $primaryColor;
            }

            .subsectionTitle {
                font-size: 16px;
                line-height: 21px;
                font-weight: 700;
                margin-bottom: 12px;
                color: $primaryColor;
            }

            .subsectionsBox {
                margin-left: 20px;
            }

            .pointsContainer,
            .subsectionsBox {
                gap: 12px;
            }

            .points {
                gap: 24px;
                justify-content: space-between;
                &:not(:last-child) {
                    padding-bottom: 16px;
                    border-bottom: 1px solid #e2dfdf;
                }
            }

            .subsectionsContainer {
                border-left: 2px solid $primaryColor;
                gap: 12px;
            }

            .deleteIrelevantBtn {
                height: min-content;
                margin-left: 24px;
            }
        }

        .dropdownIcon {
            svg {
                color: $primaryColor;
            }
        }
    }

    .reEvaluateBtn {
        margin-left: 14px;
        font-size: 16px;
        box-shadow: none;
        height: 36px;
        float: inline-end;

        svg {
            font-size: 16px;
            margin-left: 8px;
        }
    }
}

.exportPdfBackdrop{
    z-index: 999;
}