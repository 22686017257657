@import 'commonStyles.scss';
@import 'constants.scss';
@import 'variables.scss';

.roadmapMetricsContainer {
    height: 100vh;
    overflow: hidden;

    .roadmapMetrics {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        overflow: auto;
        padding: 0px 16px 16px 16px;
    
        .header {
            padding: 14px 0;
            border-bottom: 1px solid $surface2;
            margin-bottom: 16px;
            position: sticky;
            top: 0;
            z-index: 1000;
            background-color: #FFF;
    
            .title {
                color: $textColor;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
            }
        }
    
        .sectionHeader {
            .title {
                color: $textColor;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
            }
        
            .description {
                color: #71717A;
            }
        }
    
        .section {
            border-radius: 12px;
            border: 1px solid $surface2;
            background: #FFF;
            box-shadow: 0px 3px 4px -2px rgba(153, 145, 145, 0.10);
            padding: 20px;
            min-width: 400px;
    
            @media (max-width: 768px) {
                min-width: 300px;
            }
    
            @media (max-width: 630px) {
                min-width: 200px;
            }
        }
    
        .staticPieChart {
            font-size: 16px;
        }
    
        .overview {
            .contentBlock {
                min-width: 400px;
                flex: 1; 
                flex-wrap: wrap;
                color: $textColor;
                line-height: 20px;
                border-radius: 12px;
                border: 1px solid $surface2;
    
                @media (max-width: 768px) {
                    min-width: 100%;
                }
    
                .title {
                    font-size: 17px;
                    font-weight: 500;
                }
    
                .item {
                    padding: 12px;
                    border-radius: 12px;
                    background: $surfacesBackgroundColor;
    
                    &.critical {
                        background-color: $lightRed;
                        .value {
                            color: $darkRed;
                        }
                    }
    
                    &.high {
                        background-color: #FFE6E9;
                        .value {
                            color: #FF7986;
                        }
                    }
    
                    &.medium {
                        background-color: #FFF4E9;
                        .value {
                            color: #FF993A;
                        }
                    }
    
                    &.low {
                        background-color: $mediumGreen;
                        .value {
                            color: $darkGreen;
                        }
                    }
    
                    .label {
                        font-size: 15px;
                    }
    
                    .value {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 24px; 
                    }
                }
            }
        }
    
        .datePickerBox {
            .datePickerBox-label {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: $primaryColor;
                white-space: nowrap;
            }
        }
    
        .initiatives-table-container {
            margin-top: 32px;
            margin-bottom: 32px;

            .initiatives-table-title {
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }

    .roadmapMetricsDaily {
        height: 400px;
    }

    .metricsBurnDownChart {
        min-height: 400px;

        .tooltip-content {
            background-color: #fff;
            padding: 10px;
            border: 1px solid $surface2;
            gap: 10px;
            border-radius: 4px;
        }
    }

    .legend-items {
        font-size: 16px;

        .status{
            font-weight: 500;
            line-height: 24px; 
        }
    
        .legend-title {
            color: $textColor;
            font-weight: 500;
            line-height: 24px;
            text-transform: capitalize
        }
    
        .legend-dot {
            box-sizing: border-box;
            width: 12px;
            height: 12px; 
            border-radius: 50%;
        }
    
        .legend-percentage {
            color: #71717A;
            font-size: 15px;
            font-weight: 300;
            line-height: 15px
        }
    }

    .statistics-card {
        height: 440px;
        min-width: 350px;
        padding: 16px;
        flex: 1 0 0;
        border-radius: 16px;
        border: $border-surface2;
        background: $background-white;
        box-shadow: $shadow-long;
        box-sizing: border-box;
    }
}

