@import 'constants.scss';

.infoTitleBox{
    max-width: 920px;
    font-size: 24px; 
    margin-left: -8px;
    margin-top: -10px;
    
    .infoTitleInput{
        color: $secondaryColor;
        font-size: 32px;
        
        ::placeholder {
            font-size: 32px;
        }
    }
}

.infoTitleBoxRight{
    flex-shrink: 0;

    .infoItem{
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        color: $secondaryColor;
        margin: 0 8px;

        .icon{
            margin-right: 4px;

            svg{
                font-size: 20px;
            }
        }
    }

    .selectStatus{
        margin: 0 15px 0 30px
    }
    
}

.menuItem{
    font-size: 14px;
    padding: 4px 10px; 
    margin-bottom: 2px;
}

.container{
    padding: 0 38px 32px;
    max-width: 860px;
}