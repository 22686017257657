@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.selector-container {
    padding: 4px 12px;
    gap: 8px;
    border-radius: 8px;
    background: $surfaces-backgroundcolor;
    color: $text-secondarytextcolor;

    &:hover {
        background: $surfaces-surface1;
    }

    &.active {
        background: $white;
        color: $text-textcolor;

        &:hover {
            background: $surfaces-surface1;
            color: $text-tertiarytextcolor;
        }
    }

    &.non-editable {
        background: $white;
        color: $text-textcolor;
    }

    &.non-editable-disabled {
        background: $white;
        color: $text-tertiarytextcolor;
    }
}

.dropdown-container {
    border-radius: 12px;
    border: 1px solid transparent;
    height: 36px;
    gap: 8px;

    &.white {
        background: rgba(255, 255, 255, 0.60);
    }

    &.outlined {
        border: 1px solid $surfaces-surface2;
        background: #F9FAFA;
    }

    &.filled {
        background: $surfaces-surface2;
    }

    &.large {
        padding: 10px 12px;
    }

    &.medium {
        padding: 8px 12px;
    }

    &.small {
        padding: 6px 16px;
    }

    &.extra-small {
        padding: 4px 12px;
    }

    .dropdown-content {
        .dropdown-text {
            color: var(--Text-TextColor, #2F3237);
            text-align: center;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.09px;
        }
    }
}