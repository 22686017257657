@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.comments {
    overflow-y: hidden;
    height: calc(100vh - 82px);
    
    .header {
        padding-top: 16px;
        gap: 12px;

        .header-title {
            color: $text-textcolor;
        }
    }

    .comments-container {
        overflow-y: auto;
    }

    .comment-block-container {
        border-radius: 16px;
        border: 1px solid $surfaces-surface1;
        background: $surfaces-backgroundcolor;
        padding: 4px;

        .comment-block {
            border-radius: 12px;
            border: 1px solid $surfaces-surface1;
            background: $neutral-white;
            padding: 8px;
            gap: 12px;

            .header {
                .title {
                    color: $text-tertiarytextcolor;
                }

                .avatar {
                    width: 32px;
                    height: 32px;
                    border-radius: 228.571px;
                    border: 1.143px solid $surfaces-surface1;
                    background: $accents-light-purple;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 900;
                    line-height: 16px;
                }
            }
        }
    }
}