@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.overviewContainer {
    font-family: $font-family;
    padding: 16px 16px 8px 16px;
    border-radius: 16px;
    border: 1px solid $gray-gray50;
    background: $white;

    .header {
        .title {
            color: $gray-gray100;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px; /* 116.667% */
            letter-spacing: -0.48px;
            font-family: "Inter";
            max-width: 500px;
        }

        .description {
            color: $gray-gray80;

            .description-value {
                color: $gray-gray90;
            }
        }
    }

    .footer {
        color: $gray-gray80;
        
        .footer-text {
            color: $gray-gray80;
        }
    }
}