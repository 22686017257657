@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.customConfirmationDialog{
    border-radius: 16px;
    border: 1px solid $surfaces-surface2;
    background: $white;
    box-shadow: $shadow-akhooekhto;
    max-width: 416px;
    padding: 4px 4px 16px 4px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialogCenterIcon {
        color: $icon-iconcolor;
        
        svg {
            width: 56px;
            height: 56px;
        }
    }

    .dialogContent {
        padding: 0px 12px;
        gap: 24px;
        width: 82%;
    }

    .closeIcon {
        align-self: flex-end;
        color: $icon-iconcolor;
    }

    .dialogActions {
        width: 100%;
        gap: 4px;
    }
}